/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import '../../@core/scss/base/colors.scss';
// src/@core/scss/base/colors.scss
.inline-slash {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  padding-left: 0;
  padding-right: 0;
  font-size: large;
  width: 8px;
}

.inline-r {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  font-size: large;
  width: 15px;
  display: inline-block;
}

.plate {
  font-size: 40px;
  padding: 19px 35px 18px 55px;
  border-radius: 7px;
  box-shadow: inset 0px 0px 0px 1px #222;
  border: 1px solid #222;
  display: inline-block;
  font-weight: bold;
  position: relative;
  cursor: pointer;

  &.selected {
    color: $switch-bg-color;
    border-color: #0065a3; /* Change this to the border color for the selected state */

    &::after {
      background: #0065a3; /* Change this to the background color for the ::after pseudo-element in the selected state */
    }
  }

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 35px;
    height: 100%;
    background: #0065a3;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
}


.vehicle-preview {
  dt {
    font-weight: 900;
  }

  .rdt_TableRow {
    cursor: pointer;
  }
}

.file-list {
  max-height: 8rem;
  //width: 40rem;

  //.list-item {
  //  border-bottom: 1px solid #3b4253;
  //}

  .list-group-item {
    padding: 0.35rem 0.6rem;
  }

  .badge {
    cursor: pointer;
  }
}

.legend-color-box {
  width: 1rem;
  height: 1rem;
  background-color: rgba(234, 84, 85, 0.1);
  border-radius: 2px;
  margin-right: 5px;
  margin-top: 3px;
  display: inline-block;
}

textarea.form-control {
  &:disabled,
  &[readonly='readonly'] {
    opacity: 0.8;
  }
}

.tyre-select {
  .react-select {
    z-index: 2;
  }
}

.inline-select {
  width: 7rem
}

.wear-control {
  background-color: #6e7480;
}

.clickable-table {
  .rdt_TableRow {
    cursor: pointer;
  }
}

.calculated-value-div {


  padding: 1rem;
  background-color: rgba($color: #606060, $alpha: 0.1);
  box-shadow: 0 0 2px var(--grey-500);
  border-radius: 5px;
  width: fit-content;

  .calculated-value {
    font-size: large;
  }

}

.move-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.general-info-form {
  flex: 4 1 0;
}

.general-info-purchase {
  border-left: 1px solid #6e6b7b;
}
